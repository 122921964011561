<template>
  <div class="exam-wrapper p-3 h-screen" style="background: #f2f2f6;">
    <!-- mobile -->
    <el-drawer
      :visible.sync="mobileSide"
      direction="ltr"
      size="50%"
      :show-close="false"
    >
      <div class="px-3">
        <el-divider class="word-keep-all">试卷信息</el-divider>
        <p class="mb-2 text text-left">{{ exam.name }}</p>
        <div class="flex flex-wrap justify-between">
          <span>总分：{{ exam.score }}</span>
          <span>时长：{{ exam.suggest_time }}（分）</span>
        </div>
        <el-divider class="word-keep-all">考生信息</el-divider>
        <div class="flex justify-between">
          <span>账号：</span>
          <span>{{ $store.getters.username }}</span>
        </div>
        <el-divider class="word-keep-all">题目内容</el-divider>
        <div class="mt-4">
          <div v-if="exam.section.length > 0">
            <div v-for="(secItem, secIndex) of exam.section" :key="secIndex">
              <p class="mb-2">{{ secItem.title }}</p>
              <div v-if="secItem.questions.length > 0" class="flex flex-wrap">
                <el-button
                  class="mr-1 mb-2"
                  v-for="(quesItem, quesIndex) of secItem.questions" :key="quesIndex"
                  size="small"
                  :type="quesItem.is_finish ? 'success': 'primary'"
                  :plain="quesItem.is_finish ? false : true"
                  circle
                  @click="locateQuestion(secIndex, quesIndex)"
                >
                  {{ quesItem.sort.toString().padStart(2, '0') }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-around mt-4" v-if="!isEnd">
          <el-button class="w-1/2 mr-2 mb-2" type="primary" size="small" @click="readyForPostExamAnswer">提交试卷</el-button>
          <el-button class="w-1/2 mb-2" type="danger" size="small" @click="cancelExam">取消答题</el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 右侧浮动倒计时 -->
    <div
      class="lg:hidden mobile-btn px-2 py-1"
      style="border-radius: 4px 0 0 4px; background: #fefefe; z-index:10;"
    >
      <div v-if="!isEnd">
        <span>考试剩余时间</span>
        <mv-count-down
          class="bg-warning text-gray-400 px-2 rounded text-center text-sm"
          @endCallback="countDownEnd"
          :startTime="startTime"
          :endTime="endTime"
          endText="考试时间已到"
          :dayTxt="'天'"
          :hourTxt="'小时'"
          :minutesTxt="'分'"
          :secondsTxt="'秒'"
          :isStart="isStart"
        >
        </mv-count-down>
      </div>
      <el-button class="mt-1" size="mini" @click="mobileSide = true">试卷选项</el-button>
    </div>
    <!-- end mobile -->
    <div class="flex">
      <div class="left w-1/5 lg:block hidden p-4 rounded mr-5 overflow-y-auto" style="height: 95vh;background: #fefefe;">
        <el-divider class="word-keep-all">试卷信息</el-divider>
        <p class="mb-2 text text-left">{{ exam.name }}</p>
        <div class="flex flex-wrap justify-between">
          <span>总分：{{ exam.score }}</span>
          <span>时长：{{ exam.suggest_time }}（分）</span>
        </div>
        <div v-if="!isEnd">
          <el-divider class="word-keep-all">剩余时间</el-divider>
          <mv-count-down
            class="bg-warning text-gray-400 px-2 mb-2 rounded text-center"
            style="font-size: 30px;"
            @endCallback="countDownEnd"
            :startTime="startTime"
            :endTime="endTime"
            endText="考试时间已到"
            :dayTxt="'天'"
            :hourTxt="'小时'"
            :minutesTxt="'分'"
            :secondsTxt="'秒'"
            :isStart="isStart"
          >
          </mv-count-down>
          <div class="flex justify-around">
            <el-button class="w-1/2 mr-2 mb-2" type="primary" size="small" @click="readyForPostExamAnswer">提交试卷</el-button>
            <el-button class="w-1/2 mb-2" type="danger" size="small" @click="cancelExam">取消答题</el-button>
          </div>
        </div>
        <el-divider class="word-keep-all">考生信息</el-divider>
        <div class="flex justify-between">
          <span>账号：</span>
          <span>{{ $store.getters.username }}</span>
        </div>
        <el-divider class="word-keep-all">题目内容</el-divider>
        <div class="mt-4">
          <div v-if="exam.section.length > 0">
            <div v-for="(secItem, secIndex) of exam.section" :key="secIndex">
              <p class="mb-2">{{ secItem.title }}</p>
              <div v-if="secItem.questions.length > 0" class="flex flex-wrap">
                <el-button
                  class="mr-1 mb-2"
                  v-for="(quesItem, quesIndex) of secItem.questions" :key="quesIndex"
                  size="small"
                  :type="quesItem.is_finish ? 'success': 'primary'"
                  :plain="quesItem.is_finish ? false : true"
                  circle
                  @click="locateQuestion(secIndex, quesIndex)"
                >
                  {{ quesItem.sort.toString().padStart(2, '0') }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
        
        
      </div>
      <div class="right lg:w-4/5 w-full p-4 overflow-y-auto rounded" style="height: 95vh; background: #fefefe;" ref="content">
        <div v-if="exam.section.length > 0">
          <div v-for="(secItem, secIndex) of exam.section" :key="secIndex">
            <p class="bg-gray-200 mb-2 py-3 rounded text-xl text-gray-700">{{ secItem.title }}</p>
            <div v-if="secItem.questions.length > 0">
              <div class="mb-3" :data-id="`quesBox${secIndex}quesId${quesIndex}`" :ref="`quesBox${secIndex}`" v-for="(quesItem, quesIndex) of secItem.questions" :key="quesIndex">
                <div class="flex text-left text-lg">
                  <span>{{ quesItem.sort }}. </span>
                  <div>
                    <div v-html="quesItem.title" class="mb-2"></div>
                    <!-- 选择题、判断题 -->
                    <div v-if="[0, 1].includes(quesItem.question_type) && quesItem.items.length > 0">
                      <el-radio-group v-model="quesItem.answer" class="flex flex-col" @change="setAnswer(quesItem)">
                        <el-radio
                          v-for="(item, itemIndex) of quesItem.items"
                          :key="itemIndex"
                          :label="item.prefix"
                          class="mb-2"
                        >
                          <div class="flex" style="font-size: 18px;">
                            {{ item.prefix }}.
                            <div v-html="item.content"></div>
                          </div>
                        </el-radio>
                      </el-radio-group>
                    </div>
                    <!-- 多选题 -->
                    <div v-else-if="quesItem.question_type === 2 && quesItem.items.length > 0">
                      <el-checkbox-group
                        v-model="quesItem.answer_array"
                        @change="setAnswer(quesItem)"
                      >
                        <el-checkbox
                          v-for="(item, itemIndex) of quesItem.items"
                          :key="itemIndex"
                          :label="item.prefix"
                        >
                          <div class="flex" style="font-size: 18px;">
                            <span>{{ item.prefix }}. </span>
                            <div v-html="item.content"></div>
                          </div>
                        </el-checkbox>
                      </el-checkbox-group>
                    </div>
                    <!-- 求解题 -->
                    <div v-else-if="quesItem.question_type === 3 || quesItem.question_type === 5">
                      <div class="flex items-center mb-2" v-for="enter in quesItem.enter_num" :key="enter">
                        <span>（{{ enter }}）</span>
                        <el-input v-model="quesItem.answer_array[enter-1]" @input="setAnswer(quesItem)"></el-input>
                      </div>
                    </div>
                    <!-- 图形化编程题 -->
                    <div v-else-if="quesItem.question_type === 4 && quesItem.type === 0">
                      <el-button type="primary" icon="el-icon-edit" @click="openScratch(quesItem.project_id)">开始编程</el-button>
                    </div>
                    <!-- C++ 或 Python -->
                    <div v-else-if="quesItem.question_type === 4 && [1,2].includes(quesItem.type)">
                      <span>填写代码：</span>
                      <editor :answer="quesItem.answer" :type="quesItem.type" @update-answer="updateAnswer($event, quesItem)"/>  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="考试结束提示"
      :visible.sync="autoPostDialog"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <p>考试结束时间已到，试卷将在 {{ autoTime }} 秒后自动提交。</p>
      <span slot="footer" class="dialog-footer">
        <el-button class="mr-2" size="small" @click="cancelExam">退出考试</el-button>
        <el-button size="small" type="primary" :loading="autoPostLoading" @click="postExamAnswer">立即提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import settings from '@/setting'
import { isLaptop } from '@/utils/device'
import { detail, postExam } from '@/api/exam'

import MvCountDown from 'mv-count-down'
import Editor from './componentes/editor.vue'

export default {
  name: 'ExamDetail',
  data() {
    return {
      exam: {
        id: 0,
        name: '',
        section: [],
        score: 0,
        suggest_time: 0,
        level: 0
      },
      startTime: 0,
      endTime: 0,
      isStart: false,
      isEnd: false,
      mobileSide: false,
      autoPostDialog: false,
      autoPostLoading: false,
      autoTime: 10
    }
  },
  components: {
    MvCountDown,
    Editor
  },
  created() {
     if (!isLaptop()) {
      this.$router.push('/exam/need-laptop')
      return false
    }
  },
  mounted() {
    this.exam.id = this.$route.query.id
    this.init()
  },
  methods: {
    init() {
      const loading = this.$loading({
        lock: true,
        text: '试卷加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      detail({ id: this.exam.id }).then(res => {
        this.exam = res.data
        this.startTime = new Date().getTime()
        this.endTime = this.startTime + parseInt(this.exam.suggest_time) * 60 * 1000
        this.isStart = true
        this.$message({
          message: '考试开始！请在规定的时间内完成作答哟~加油 (ง •_•)ง',
          type: 'success',
        })
        loading.close();
      })
    },
    // 设置回答
    setAnswer(quesItem) {
      quesItem.is_finish = 1
      if (quesItem.question_type === 2 && quesItem.answer_array.length === 0) {
        quesItem.is_finish = 0
      }
      if ([3,5].includes(quesItem.question_type)) {
        const tempArr = quesItem.answer_array.filter(item => {
          return item
        })
        if (tempArr.length === 0) {
          quesItem.is_finish = 0
        }
      }
    },
    locateQuestion(secIndex, quesIndex) {
      let px = this.$refs[`quesBox${secIndex}`][quesIndex].offsetTop + 1
      this.$nextTick(() => {
        this.$refs.content.scrollTo(0, px - 20)
      })
    },
    countDownEnd () {
      // 结束倒计时回调
      this.isEnd = true
      this.autoPostDialog = true
      let timer = setInterval(() => {
        if (this.autoTime <= 0) {
          clearInterval(timer)
          this.autoPostDialog = false
          this.postExamAnswer()
        } else {
          this.autoTime--;
        }
      }, 1000)
    },
    // 取消答题
    cancelExam() {
      this.$confirm('你确定要退出答题吗？').then(() => {
        this.sidebarStatus = false
        // this.$router.go(-1)
        window.close()
      }).catch(() => {
        console.log('exit exam error')
      })
    },
    // 准备提交试卷
    readyForPostExamAnswer() {
      // 检查是否还有为完成的题目
      // 如果时间已到，就不用检查
      if (!this.isEnd) {
        let check = this.checkAnswerStatus()
        if (check.length > 0) {
          this.$confirm(`你还有第 ${check.join('，')} 题，共计 ${check.length} 道题没有完成，确认要提交试卷吗？`)
              .then(() => {
                this.postExamAnswer()
              })
              .catch(() => {})
        } else {
          this.postExamAnswer()
        }
      } else {
        this.postExamAnswer()
      }
      
    },
    // 检查完成题目状态
    checkAnswerStatus() {
      let list = []
      this.exam.section.forEach(item => {
        let arr = item.questions.filter(q => {
          return q.is_finish == 0
        }).map(m => { return m.sort})
        list = [...new Set([...list, ...arr])]
      })
      return list
    },
    // 确认提交试卷
    postExamAnswer() {
      let answerList = []
      let finishTime = 0 // 秒为单位

      this.autoPostDialog = false

      this.exam.section.forEach(item => {
        let arr = item.questions.map(m => {
          let mapData = {'aid': m.aid, 'qid': m.id, 'answer': m.answer}
          if (m.answer_array) {
            mapData['answer_array'] = m.answer_array
          }
          return mapData
        })
        answerList = [...new Set([...answerList, ...arr])]
      })
      if (this.isEnd) {
        finishTime = this.exam.suggest_time * 60
      } else {
        finishTime = Math.floor((new Date().getTime() - this.startTime) / 1000)
      }
      this.allowPostBtn = true
      postExam({
        user_exam_id: this.exam.user.id,
        answer_list: answerList,
        finish_time: finishTime,
      }).then(res => {
        this.$message({
          message: '试卷提交成功！',
          type: 'success',
        })
        this.isEnd = true
        this.$confirm(`你本次的考试成绩为：${res.data.score}`, '考试结果', {
          confirmButtonText: '答案解析',
          cancelButtonText: '关闭'
        }).then(() => {
          this.$router.push({ path: '/exam/analysis', query: { id: res.data.id }})
        }).catch(() => {
          window.close()
        })
      })
    },
    openScratch(id) {
      const href = `${settings.scratchUrl}?edit#${id}`
      window.open(href, 'scratch')
    },
    updateAnswer(e, quesItem) {
      quesItem.answer = e
      if (quesItem.answer.trim() !== '') {
        quesItem.is_finish = 1
      } else {
        quesItem.is_finish = 0
      }
    }
  }
}
</script>

<style scoped>
.el-button+.el-button {
  margin-left: 0
}
.mobile-btn {
  position: fixed;
  top: 50%;
  right: 0;
}
.word-keep-all {
  word-break: keep-all;
}
.el-radio {
  display: flex;
}
</style>